import logo from './logo.svg';
import { Layout, Menu, Button, Spin, Typography } from 'antd';
import {
  DownOutlined,
  IdcardOutlined,
  DollarOutlined,
  ShopOutlined,
  MenuOutlined
} from '@ant-design/icons';
import { Route, Routes, Link } from "react-router-dom";
import './App.css';
const { Header, Content, Footer } = Layout;
const { Title, Text } = Typography;

function App() {

  const newMenuProps = [
    {
      label: (
        <a href="https://airtable.com/shrqJKoRbtE61zaR2" target="_blank" rel="noopener noreferrer">
          Company
        </a>
      ),
      key: 'newMenuProps-1',
      icon: <ShopOutlined />,
    },
    {
      label: (
        <a href="https://airtable.com/shr0JqPYJx3KMihvZ" target="_blank" rel="noopener noreferrer">
          Capital & Financing Entity
        </a>
      ),
      key: 'newMenuProps-2',
      icon: <DollarOutlined />,
    },
    {
      label: (
        <a href="https://airtable.com/shrqXpUypHLVy80i9" target="_blank" rel="noopener noreferrer">
          Person
        </a>
      ),
      key: 'newMenuProps-3',
      icon: <IdcardOutlined />,
    },
  ];

  const updateMenuProps = [
    {
      label: (
        <a href="https://airtable.com/shrv6HVlSEQDnNsXa" target="_blank" rel="noopener noreferrer">
          Company
        </a>
      ),
      key: 'updateMenuProps-1',
      icon: <ShopOutlined />,
    },
    {
      label: (
        <a href="https://airtable.com/shr9cwRCm1XO0vG9f" target="_blank" rel="noopener noreferrer">
          Capital & Financing Entity
        </a>
      ),
      key: 'updateMenuProps-2',
      icon: <DollarOutlined />,
    },
    {
      label: (
        <a href="https://airtable.com/shrUJZHYx7CpAYVTH" target="_blank" rel="noopener noreferrer">
          Person
        </a>
      ),
      key: 'updateMenuProps-3',
      icon: <IdcardOutlined />,
    },
  ];

  const renderAboutPage = () => {
    const titleLevel = 3;
    return (
      <div className='about-page-container'>
        <Title level={titleLevel}>About us</Title>
        <Text>Climate Market is a community-driven effort to source, tie together,
        and surface data about the climate tech space for the benefit of founders,
        investors, and climate tech enthusiasts. We are a small team that
        adds climate companies, people, and capital sources to our dataset as we
        come across them. Our goal is to make this data freely available to the
        climate community to help spur the growth of and investment in climate tech
        companies and solutions. We apologize in advance for any mistakes in the
        data and graciously request your help fixing them.</Text>
        <br/><br/>
        <Text strong>If you see a mistake or don’t see your company, fund, or yourself
        in our dataset, please help us and add them using the buttons in the
        header of this page!</Text>

        <Title level={titleLevel}>Who is Climate Market for?</Title>
        <Text>Climate Market is primarily built for two major personas: climate tech
        founders and climate tech investors. The goal of the Climate Market dataset
        is to tie together companies, funding sources, and influential people to
        surface market & innovation gaps, winning/losing go-to-market strategies,
        investment opportunities, and general patterns in climate tech. </Text>
        <br/><br/>
        <Text>Despite these focus areas, many people outside of these groups have
        found value in the data and have helped contribute to the dataset. If
        you find value in the data outside of these two major use cases, <a href="mailto:hello@climatemarket.co" target="_blank" rel="noopener noreferrer">let us know!</a></Text>

        <Title level={titleLevel}>Our vision</Title>
        <Text>Our vision for Climate Market is to engage the climate community
        to build and maintain a comprehensive dataset of every company and investor
        that exists in the climate tech space so that we can make Climate Market
        the definitive source of truth for climate tech market data.</Text>

        <Title level={titleLevel}>We need your help!</Title>
        <Text>We’re a small team based out of Chicago IL, and mostly add new
        data manually. We need your help to add and enrich the dataset for the
        benefit of the community. If you see incorrect or missing information
        please help us fix it! You can use the buttons in the
        header of this page to make submissions. Thank you in advance!</Text>

        <Title level={titleLevel}>Disclaimer</Title>
        <Text>The information on Climate Market ("this site") is provided for
        general informational purposes only. While we strive to ensure the accuracy
        of the information we publish, we make no guarantees as to the accuracy,
        completeness, timeliness, or reliability of any information provided on
        this site. The information on this site is obtained from a variety of sources,
        including open sources and self-reported data, and we cannot verify the
        accuracy or reliability of all information provided. The information on
        this site should not be relied upon for any purpose without independent
        verification. Any reliance you place on such information is therefore
        strictly at your own risk. We make no representations or warranties of any
        kind, express or implied, about the completeness, accuracy, reliability,
        suitability, or availability with respect to the information on this site
        or the information, products, services, or related graphics contained on
        this site for any purpose. In no event will we be liable for any loss or
        damage including without limitation, indirect or consequential loss or damage,
        or any loss or damage whatsoever arising from loss of data or profits
        arising out of, or in connection with, the use of this site.</Text>
      </div>
    );
  }

  const renderHomePage = () => {
    return (
      <>
        <iframe
          title="airtable"
          className="airtable-embed"
          src="https://airtable.com/embed/shrijky4mTWOOOaGP"
          frameBorder="0"
          width="100%"
          height="100%"
          style={{"background": "transparent"}}>
        </iframe>
        <div className='loading-container'>
          <Spin tip="Loading..." size="large" />
        </div>
      </>
    );
  }

  return (
    <div className="App">
      <Layout className="layout" style={{ backgroundColor: 'white' }}>
        <Header
          style={{
            background: 'white',
            marginBottom: '20px',
            borderBottom: '2px solid #f3f4f6',
            padding: '0 30px'
          }}>
          <Link to='/'>
            <div className="logo">
              <img src={logo} alt="Logo"/>
            </div>
          </Link>
          <Menu
            className="menu-container"
            theme="light"
            mode="horizontal"
            defaultSelectedKeys={['']}
            selectedKeys={[]}
            style={{ "justifyContent": "flex-end" }}
            overflowedIndicator={<MenuOutlined />}
            expandIcon={<span></span>}
            triggerSubMenuAction={'click'}
            items={[
              {
                key: 1,
                label: <Link to='/about'>About</Link>
              },
              {
                key: 2,
                label: (
                  <a href="mailto:hello@climatemarket.co" target="_blank" rel="noopener noreferrer">
                    Contact
                  </a>
                ),
              },
              {
                key: 3,
                label: (
                  <Button type="primary">
                    Submit New
                    <DownOutlined />
                  </Button>
                ),
                children: newMenuProps
              },
              {
                key: 4,
                label: (
                  <Button>
                    Submit Update
                    <DownOutlined />
                  </Button>
                ),
                children: updateMenuProps
              }
            ]}
          />
        </Header>
          <Content
            style={{
              margin: '0 29px',
              borderRadius: '5px',
              border: "1px solid #f3f4f6",
              boxShadow: "0 1px 3px rgb(0 0 0 / 5%), 0 1px 2px rgb(0 0 0 / 10%)"
            }}
          >
            <div
              className="site-layout-content"
              style={{ background: 'white' }}
            >
              <Routes>
                <Route exact path={'/'} element={renderHomePage()}/>
                <Route exact path={'/about'} element={renderAboutPage()}/>
              </Routes>
            </div>
          </Content>
        <Footer
          style={{
            textAlign: 'center',
            marginTop: '20px'
          }}
        >
          ©{new Date().getFullYear()} climatemarket.co. All rights reserved.
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
